<template>
  <div class="lb-bp-25">
    <div v-if="!loading_content" class="profile-ed-mn">
      <template v-if="personalInfo">
        <div class="lb-mb-20">
          <div class="title text-media h4 lb-mb-2">
            <span class="max-l-b">Personal info</span>
          </div>
          <div class="max-l-b text-md">Contact and additional information about you for the client.</div>
        </div>
        <div class="form-group md lb-mb-15">
          <div class="row row-24">
            <div class="col-lg-6">
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.email}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.personalInfo.email.$touch()"
                    @input="typedInput"
                    v-model="personalInfo.email"
                    :class="{'error': $v.personalInfo.email.$error}"
                  >
                  <label>E-mail</label>
                </div>
                <div v-if="$v.personalInfo.email.$error" class="input-error">Error</div>
              </div>
              <div
                class="sl-select-wrapper-material"
                :class="{'typed': personalInfo.country !== 0}"
              >
                <label>Country</label>
                <multiselect
                  v-model="personalInfo.country"
                  :options="options_country.map(e => e.id)"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder=""
                >
                  <template slot="singleLabel" slot-scope="{ option }"><span v-if="personalInfo.country !== 0">{{options_country.find(e => e.id === option).name}}</span><span v-else> </span></template>
                  <template slot="option" slot-scope="props">{{options_country.find(e => e.id === props.option).name}}</template>
                </multiselect>
              </div>
              <div
                class="ml-select-wrapper-material"
                :class="{'typed': personalInfo.langs.length > 0}"
              >
                <multiselect
                  tag-placeholder="Add this as new tag"
                  placeholder=""
                  v-model="personalInfo.langs"
                  :options="options_lang.map(e => e.value)"
                  :multiple="true"
                >
                  <span slot="noResult">Oops! No elements found.</span>
                </multiselect>
                <label>Languages</label>
              </div>
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.fb}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.personalInfo.fb.$touch()"
                    @input="typedInput"
                    v-model="personalInfo.fb"
                    :class="{'error': $v.personalInfo.fb.$error}"
                  >
                  <label>Facebook</label>
                </div>
                <div v-if="$v.personalInfo.fb.$error" class="input-error">Error</div>
              </div>
            </div>

            <div class="col-lg-6">
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.phone}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event)"
                    @input="typedInput"
                    v-model="personalInfo.phone"
                  >
                  <label>Phone number</label>
                </div>
              </div>
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.location}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.personalInfo.location.$touch()"
                    @input="typedInput"
                    v-model="personalInfo.location"
                    :class="{'error': $v.personalInfo.location.$error}"
                  >
                  <label>Location</label>
                </div>
                <div v-if="$v.personalInfo.location.$error" class="input-error">Error</div>
              </div>
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.ln}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.personalInfo.ln.$touch()"
                    @input="typedInput"
                    v-model="personalInfo.ln"
                    :class="{'error': $v.personalInfo.ln.$error}"
                  >
                  <label>LinkedIn</label>
                </div>
                <div v-if="$v.personalInfo.ln.$error" class="input-error">Error</div>
              </div>
              <div
                class="input-wrapper-material focus-st"
                :class="{'typed': personalInfo.inst}"
              >
                <div class="input-inner">
                  <input
                    class="input input-material typed"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.personalInfo.inst.$touch()"
                    @input="typedInput"
                    v-model="personalInfo.inst"
                    :class="{'error': $v.personalInfo.inst.$error}"
                  >
                  <label>Instagram</label>
                </div>
                <div v-if="$v.personalInfo.inst.$error" class="input-error">Error</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="question">
        <div class="lb-mb-24">
          <div class="title text-media h4 lb-mb-20">
            <span class="max-l-b">Information</span>
          </div>
          <div
            class="lb-mb-15"
            v-for="(item, i) in question"
            :key="i + 's'"
          >
            <div class="h5 lb-mb-15">{{question.a.title}}</div>
            <information-checkbox
              :options="item.options"
              :type="item.type"
              :is-event="isEvent"
              @сhooses="choosesOption"
              @loaded="loadedOption"
            ></information-checkbox>
          </div>
        </div>
      </template>
      <template v-if="user_achievements">
        <div class="lb-mb-24">
          <div class="title text-media h4 lb-mb-20">
            <span class="max-l-b">Fill in the fields according to your achievements, certificates</span>
          </div>
          <div class="form-group">
            <div class="input-wrapper-material" :class="{'typed': achievements.since}">
              <div class="input-inner">
                <label>Coach since</label>
                <vc-date-picker
                  v-model="achievements.since"
                  is24hr
                  :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="input input-material"
                      @focus="focusInput"
                      @blur="blurInput($event)"
                      @input="typedInput"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </vc-date-picker>
              </div>
            </div>
            <div class="input-wrapper-material" :class="{'typed': achievements.school}">
              <div class="input-inner">
                <label>Name of coaching school</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="achievements.school"
                >
              </div>
            </div>
            <div
              v-if="options_cert.length"
              class="ml-select-wrapper-material without_search"
              :class="{'typed': user_achievements.certifications.length}"
            >
              <label>Coaching certification</label>
              <multiselect
                tag-placeholder="Add this as new tag"
                placeholder=""
                :searchable="false"
                label="title"
                track-by="title"
                v-model="user_achievements.certifications"
                :options="options_cert"
                :multiple="true"
              >
                <span slot="noResult">Oops! No elements found.</span>
              </multiselect>
            </div>
            <div
              v-if="options_fed.length"
              class="sl-select-wrapper-material"
              :class="{'typed': achievements.federation_id}"
            >
              <label>Coaching federation member</label>
              <multiselect
                v-model="achievements.federation_id"
                :options="options_fed"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                placeholder=""
              >
                <template slot="singleLabel" slot-scope="{ option }"><span v-if="achievements.federation_id !== 0">{{options_fed.find(e => e.id === option.id).title}}</span><span v-else> </span></template>
                <template slot="option" slot-scope="props">{{options_fed.find(e => e.id === props.option.id).title}}</template>
              </multiselect>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Coaching school diploma as business coach</div>
              <div class="upload-wrapper">
                <button
                  @click="trigger_upload_dip"
                  class="btn btn-outlined btn-block-sm"
                  :class="{'loader': loading_save_dip}"
                >
                  Upload file
                </button>
                <br>
                <div
                  class="upload-die bg-white"
                  v-if="diplom.diplom_name"
                >
                <span class="icon">
                  <img src="@/assets/img/icons/icon-ok.svg" alt="icon">
                </span>
                  <span class="file">{{diplom.diplom_name}}</span>
                  <span class="size">{{diplom.diplom_size}}</span>
                  <button
                    class="btn clean"
                  >
                    <img src="@/assets/img/icons/icon-clear.svg" alt="icon">
                  </button>
                </div>
                <input
                  @change="change_dip($event)"
                  ref="file_input_dip"
                  type="file"
                  style="display: none"
                  accept="application/pdf,application/vnd.ms-excel"
                >
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Do you have a role in a coaching federation?</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-01" value="Yes" type="radio" v-model="achievements.federation_role"><span>Yes</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-01" value="No" type="radio" v-model="achievements.federation_role"><span>No</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Years of management experience</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-02" value="0" type="radio" v-model="achievements.management_experience"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="1-2" type="radio" v-model="achievements.management_experience"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="3-4" type="radio" v-model="achievements.management_experience"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="4-5" type="radio" v-model="achievements.management_experience"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="6-7" type="radio" v-model="achievements.management_experience"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value=">7" type="radio" v-model="achievements.management_experience"><span>>7</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Years of leadership experience</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-03" value="0" type="radio" v-model="achievements.leadership_experience"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="1-2" type="radio" v-model="achievements.leadership_experience"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="3-4" type="radio" v-model="achievements.leadership_experience"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="4-5" type="radio" v-model="achievements.leadership_experience"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="6-7" type="radio" v-model="achievements.leadership_experience"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="7" type="radio" v-model="achievements.leadership_experience"><span>>7</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">How many years have you led a group of people or an organisation?</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-04" value="0" type="radio" v-model="achievements.led_a_group"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="1-2" type="radio" v-model="achievements.led_a_group"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="3-4" type="radio" v-model="achievements.led_a_group"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="4-5" type="radio" v-model="achievements.led_a_group"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="6-7" type="radio" v-model="achievements.led_a_group"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="7" type="radio" v-model="achievements.led_a_group"><span>>7</span>
                </label>
              </div>
            </div>
            <!--
            -->
          </div>
        </div>
      </template>
      <div class="btn-wrapper">
        <button
          class="btn btn-primary btn-loader"
          :class="{'disable': $v.$invalid, 'loader': loading_save}"
          @click="sendInfoUser"
        >
          Save changes
        </button>
        <router-link tag="button" to="/" class="btn btn-secondary">Cancel</router-link>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, url } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import InformationCheckbox from '@/components/InformationCheckbox'
export default {
  data () {
    return {
      personalInfoOld: null,
      loading_info: false,
      loading_ques: false,
      loading_ach: false,
      loading_fed: false,
      loading_country: false,
      loading_lang: false,
      loading_cert: false,
      loading_save_info: false,
      loading_save_ques: false,
      loading_save_ach: false,
      loading_save_dip: false,
      isEvent: false,
      check_old: [],
      check: []
    }
  },
  computed: {
    personalInfo () {
      return this.$store.getters.personalInfoEdit
    },
    options_country () {
      return this.$store.getters.personal_country
    },
    options_lang () {
      return this.$store.getters.personal_language
    },
    question () {
      return this.$store.getters.user_information
    },
    user_achievements () {
      return this.$store.getters.user_achievements_edit
    },
    achievements () {
      return this.user_achievements?.achievments
    },
    diplom () {
      return this.$store.getters.user_achievements_edit?.diplom
    },
    options_fed () {
      return this.$store.getters.options_federation
    },
    options_cert () {
      return this.$store.getters.options_cert
    },
    loading_together () {
      if (!this.loading_lang && !this.loading_country && !this.loading_fed && !this.loading_cert) {
        return false
      } else {
        return true
      }
    },
    loading_content () {
      if (!this.loading_info && !this.loading_ques && !this.loading_fed) {
        return false
      } else {
        return true
      }
    },
    loading_save () {
      if (this.loading_save_info || this.loading_save_ques || this.loading_save_ach) {
        return true
      } else {
        return false
      }
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    choosesOption ({ selecteds, type }) {
      if (this.check.length > 0) {
        if (this.check.some((e) => e.type === type)) {
          this.check.forEach((e, i) => {
            if (e.type === type) {
              this.check[i].answer_id = selecteds
            }
          })
        } else {
          this.check.push({
            type: type,
            answer_id: selecteds
          })
        }
      } else {
        this.check.push({
          type: type,
          answer_id: selecteds
        })
      }
    },
    loadedOption ({ selecteds, type }) {
      this.check_old.push({
        type: type,
        answer_id: selecteds
      })
    },
    trigger_upload_dip: function () {
      this.$refs.file_input_dip.click()
    },
    change_dip: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('file', file)
      this.loading_save_dip = true
      // POST FILE
      this.$axios.post(this.$root.$data.baseURL + 'apicall/coach/savediploma', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        }
      })
        .then((r) => {
          console.log(r)
          this.loading_save_dip = false
          this.fetchPersonalInfo()
        })
        .catch(function (e) {
          console.log(e.response)
          this.loading_save_dip = false
        })
      // POST FILE (end)
    },
    sendInfoUser: function () {
      this.loading_save_info = true
      this.loading_save_ques = true
      this.loading_save_ach = true
      this.$store.dispatch('sendPersonalInfo')
        .finally(() => {
          this.loading_save_info = false
          this.personalInfoOld = JSON.stringify(this.personalInfo)
        })
      this.$store.dispatch('sendUserInformation', this.check)
        .finally(() => {
          this.loading_save_ques = false
          this.check_old = JSON.parse(JSON.stringify(this.check))
        })
      this.$store.dispatch('sendUserAchievements')
        .finally(() => {
          this.loading_save_ach = false
        })
    },
    fetchTogether: function () {
      if (this.options_lang.length === 0) {
        this.loading_lang = true
        this.loading_info = true
      }
      this.$store.dispatch('fetchLanguage')
        .finally(() => {
          this.loading_lang = false
        })
      if (this.options_country.length === 0) {
        this.loading_country = true
        this.loading_info = true
      }
      this.$store.dispatch('fetchCountry')
        .finally(() => {
          this.loading_country = false
        })
      if (this.options_fed.length === 0) {
        this.loading_fed = true
        this.loading_info = true
      }
      this.$store.dispatch('fetchOptionsFederation')
        .finally(() => {
          this.loading_fed = false
        })
      if (this.options_cert.length === 0) {
        this.loading_cert = true
        this.loading_info = true
      }
      this.$store.dispatch('fetchOptionsCert')
        .finally(() => {
          this.loading_cert = false
        })
    },
    fetchPersonalInfo: function () {
      if (this.personalInfo === '') {
        this.loading_info = true
      }
      this.$store.dispatch('fetchPersonalInfo')
        .finally(() => {
          this.loading_info = false
          this.personalInfoOld = JSON.stringify(this.personalInfo)
        })
      if (this.question === '') {
        this.loading_ques = true
      }
      this.$store.dispatch('fetchUserInformation')
        .finally(() => {
          this.loading_ques = false
        })
      if (this.achievements === '') {
        this.loading_ach = true
      }
      this.$store.dispatch('fetchUserAchievements')
        .finally(() => {
          this.loading_ach = false
        })
    }
  },
  mounted () {
    this.personalInfoOld = JSON.stringify(this.personalInfo)
    this.fetchTogether()
  },
  beforeRouteLeave (to, from, next) {
    if (JSON.stringify(this.personalInfo) !== this.personalInfoOld || JSON.stringify(this.check) !== JSON.stringify(this.check_old)) {
      const isLeave = confirm('Are you sure you want to leave the page')
      if (isLeave) {
        next(true)
      }
    } else {
      next(true)
    }
  },
  watch: {
    loading_together: function (v) {
      if (!v) {
        this.fetchPersonalInfo()
      }
    },
    check: {
      handler: function (v) {
      }
    }
  },
  validations: {
    personalInfo: {
      email: {
        required,
        email
      },
      location: {
        required
      },
      ln: {
        url
      },
      fb: {
        url
      },
      inst: {
        url
      }
    },
    check: {
      $each: {
        answer_id: {
          required
        }
      }
    }
  },
  components: {
    Multiselect,
    InformationCheckbox
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.profile-ed{
  &-mn {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    &-image {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      margin-right: 15px;
    }
  }
}
</style>
