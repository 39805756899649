<template>
  <div
    class="checkbox-wrapper"
    v-if="options && options.length"
  >
    <label class="checkbox-entry" v-for="(option, n) in options" :key="n + 'z'">
      <input
        type="checkbox"
        class="subscribe-input"
        :value="option.id"
        v-model="selecteds"
      >
      <span>{{option.title}}</span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    isEvent: {
      type: Boolean
    }
  },
  data () {
    return {
      selecteds: [],
      is_loaded: false
    }
  },
  methods: {},
  mounted () {
    this.options.forEach((e, i) => {
      if (e.selected) {
        this.selecteds.push(e.id)
      }
      if (this.options.length === i + 1) {
        this.is_loaded = true
      }
    })
  },
  watch: {
    selecteds: {
      handler: function (v) {
        this.$emit('сhooses', { selecteds: this.selecteds, type: this.type })
      },
      deep: true
    },
    is_loaded: function (v) {
      if (v) {
        this.$emit('loaded', { selecteds: this.selecteds, type: this.type })
      }
    }
  }
}
</script>
